/* globals.css */
/* Add Google Fonts import at the top */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

/* If you specifically want Geist Sans, use this CDN (or self-host it) */
@import url('https://cdn.jsdelivr.net/npm/@fontsource/plus-jakarta-sans@4.5.0/index.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './typography.css';
@import './font-fix.css'; /* Add this import */

:root {
  background-color: rgb(17, 24, 39);

  /* Font family variables */
  --font-inter:
    'Inter', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  --font-geist: 'Plus Jakarta Sans', var(--font-inter);
  --font-sans: var(--font-geist);

  /* Z-index variables */
  --z-base: 1;
  --z-navbar: 100;
  --z-dropdown: 200;
  --z-modal: 300;
  --z-overlay: 9998;
  --z-profile: 9999;

  /* Luxury watch theme colors */
  --color-navy: 215 50% 15%;
  --color-gold: 45 80% 60%;
  --color-silver: 210 20% 90%;
  --color-bronze: 30 40% 50%;
}

body {
  background-color: rgb(17, 24, 39);
  font-family: var(--font-sans);
}

/* Font utility classes */
.font-geist {
  font-family: var(--font-geist);
}

.font-inter {
  font-family: var(--font-inter);
}

/* The rest of your CSS remains unchanged */
/* Optional: Add utility classes */
.z-navbar {
  z-index: var(--z-navbar);
}
.z-dropdown {
  z-index: var(--z-dropdown);
}
.z-modal {
  z-index: var(--z-modal);
}
.z-overlay {
  z-index: var(--z-overlay);
}
.z-profile {
  z-index: var(--z-profile);
}

@layer base {
  :root {
    /* Theme Variables */
    --background: 215 50% 15%; /* Luxury navy */
    --foreground: 0 0% 100%; /* White text */
    --card: 215 45% 20%; /* Slightly lighter navy */
    --card-foreground: 0 0% 100%;
    --popover: 215 45% 20%;
    --popover-foreground: 0 0% 100%;
    --primary: 45 80% 60%; /* Gold accent */
    --primary-foreground: 215 50% 15%; /* Navy text on gold */
    --secondary: 210 20% 90%; /* Silver accent */
    --secondary-foreground: 215 50% 15%;
    --muted: 215 30% 25%; /* Muted navy */
    --muted-foreground: 210 20% 80%; /* Light silver text */
    --accent: 30 40% 50%; /* Bronze accent */
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;

    /* Scrollbar Variables */
    --scrollbar-width: 10px;
    --scrollbar-track: rgb(15, 23, 42);
    --scrollbar-thumb: rgb(37, 99, 235);
    --scrollbar-thumb-hover: rgb(59, 130, 246);
    --scrollbar-width-thin: 6px;
    --scrollbar-track-thin: rgb(15, 23, 42);
    --scrollbar-thumb-thin: rgb(37, 99, 235);
    --scrollbar-thumb-hover-thin: rgb(59, 130, 246);
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    /* Dark theme scrollbar adjustments */
    --scrollbar-track: rgb(15, 23, 42);
    --scrollbar-thumb: rgb(37, 99, 235);
    --scrollbar-thumb-hover: rgb(59, 130, 246);
  }
}

/* Base styles */
@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

/* Custom Scrollbar Styles */
html,
body {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  overflow-y: auto;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 20px;
  margin: 4px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 50px;
  border: 3px solid var(--scrollbar-track);
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Scrollable content class */
.scrollable-content {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.scrollable-content::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.scrollable-content::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 20px;
  margin: 4px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 50px;
  border: 3px solid var(--scrollbar-track);
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Hide scrollbar class */
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
  }
}

/* Optional: Self-hosted Geist Sans font (if preferred over Plus Jakarta Sans) */
/* Uncomment and use these if you choose to self-host the Geist font files */
/*
@font-face {
  font-family: 'Geist Sans';
  src: url('/fonts/GeistSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist Sans';
  src: url('/fonts/GeistSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist Sans';
  src: url('/fonts/GeistSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
*/
